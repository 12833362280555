import React, { Component, Fragment } from 'react';
import {
  Header, Button, Modal, Form, Grid, Dropdown,
} from 'semantic-ui-react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { modalTypes } from '../../../../enums';
import { validateEmail } from '../../../../helper';

@inject('store')
@observer
class StopList extends Component {
  state = {
    modalOpen: false,
    modalType: modalTypes.add,
    deleteKey: null,
    hookObj: {
      emailDomain: this.props.store.emailManagement.activeEmailDomainName,
      email: '',
    },
  }

  get selectOptions() {
    return _.map(this.props.store.emailManagement.emailDomains, (domainSettings, emailDomain) => ({
      key: emailDomain,
      text: emailDomain,
      value: emailDomain,
    }));
  }

  createDomainDropdown(emailDomainCount = _.size(this.props.store.emailManagement.emailDomains)) {
    if (emailDomainCount > 0) {
      return (
        <div style={{ pointerEvents: 'auto', width: '70%', float: 'right' }}>
          <Dropdown
            style={
              emailDomainCount < 3
                ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                : {}
            }
            selection
            options={this.selectOptions}
            onChange={this.props.store.emailManagement.setActiveEmailDomainName}
            value={this.props.store.emailManagement.activeEmailDomainName}
          />
        </div>
      );
    }
    return null;
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
      hookObj: {
        emailDomain: this.props.store.emailManagement.activeEmailDomainName,
        email: '',
      },
    });
  }

  handleInput = (e, { value, name }) => this.setState({
    hookObj: { ...this.state.hookObj, [name]: value },
  })

  validateForm = () => {
    const { failure } = this.props.store;

    if (!validateEmail(this.state.hookObj.email)) {
      failure('Invalid email entered.');
      return false;
    }

    return true;
  }

  hookObjForm = () => (
    <Form>
      <Form.Input
        fluid
        required
        label="E-mail"
        placeholder="example@gmail.com"
        value={this.state.hookObj.email}
        name="email"
        onChange={this.handleInput}
      />
    </Form>
  );

  generateModal = () => {
    const { addEmail, deleteEmail } = this.props.store.emailSuppression;
    const { hooks } = this.props.store.clientHooks;

    switch (this.state.modalType) {
      case modalTypes.add:
        return (
          <Fragment>
            <Modal.Header>
              Add a E-mail
            </Modal.Header>
            <Modal.Content>
              {this.hookObjForm()}
            </Modal.Content>
            <Modal.Actions>
              <Button content="Cancel" onClick={this.closeModal} />
              <Button
                positive
                content="Add"
                onClick={() => {
                  if (this.validateForm()) {
                    addEmail(this.state.hookObj);
                    this.closeModal();
                  }
                }}
              />
            </Modal.Actions>
          </Fragment>
        );
      case modalTypes.remove:
        return (
          <Fragment>
            <Modal.Header>
              Are you sure you want to delete this webhook?
            </Modal.Header>
            <Modal.Content>
              {hooks[this.state.deleteKey] && hooks[this.state.deleteKey].name}
              {' '}
              will be deleted.
            </Modal.Content>
            <Modal.Actions>
              <Button content="Cancel" onClick={this.closeModal} />
              <Button
                negative
                content="Delete"
                onClick={() => {
                  deleteEmail(this.state.deleteKey);
                  this.closeModal();
                }}
              />
            </Modal.Actions>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Modal.Header>
              Something went wrong...
            </Modal.Header>
          </Fragment>
        );
    }
  }

  render() {
    const emailDomainCount = _.size(this.props.store.emailManagement.emailDomains);
    return (
      <Fragment>
        <Header id="TabTitle" as="h2">
          Unsubscribe
        </Header>
        { emailDomainCount
          ? (
            <Grid.Row>
              <Grid.Column textAlign="left">
                {this.createDomainDropdown(emailDomainCount)}
              </Grid.Column>
            </Grid.Row>
          )
          : null
        }

        <span>
          <Button
            positive
            icon="plus"
            labelPosition="left"
            content="Add a E-mail"
            onClick={() => this.setState({
              modalOpen: true,
              modalType: modalTypes.add,
              hookObj: {
                emailDomain: this.props.store.emailManagement.activeEmailDomainName,
                email: '',
              },
            })}
            disabled={Object.keys(this.props.store.clientHooks.hooks).length >= 5}
          />
        </span>

        <Modal
          open={this.state.modalOpen}
          onClose={this.closeModal}
        >
          {this.generateModal()}
        </Modal>
      </Fragment>
    );
  }
}

export default StopList;
