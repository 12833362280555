import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import Webhooks from './Webhooks';
import Emails from './Emails';
import StopList from './StopList';
import Analytics from './Analytics';

@inject('store')
class Reporting extends Component {

  constructor(props) {
    super(props);

    const { clientId } = this.props.store.auth.user;

    if (clientId === '-LdAFTqcw5zxWECiQK5-') {
      this.panes.push({
        menuItem: 'Analytics',
        render: () => <Analytics />,
      });
    }

  }
  
  panes = [
    {
      menuItem: 'Leads Data',
      render: () => <Emails />,
    },
    {
      menuItem: 'Webhooks',
      render: () => <Webhooks />,
    },
    {
      menuItem: 'E-mail stop list',
      render: () => <StopList />,
    },
  ];

  render() {
    return (
      <Tab
        panes={this.panes}
        menu={{ secondary: true, pointing: true }}
      />
    );
  }
}

export default Reporting;
