import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Button, Header, Table, Segment
  } from 'semantic-ui-react';
//import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CSVLink } from "react-csv";


@inject('store')
@observer
class Analytics extends Component {

  constructor(props) {
    super(props);

    const { planStartDate } = this.props.store.auth.user;
    const currentDay = moment().date();
    const planStartDay = moment(planStartDate).date();

    const startDate = planStartDay >= currentDay
      ? moment().subtract(1, 'month').date(planStartDay).toDate()
      : moment().date(planStartDay).toDate();

    this.state = {
      startDate,
      endDate: new Date(),
      dataToDownload: [],
    };
  }

  componentDidMount() {
    this.props.store.analytics.generateAnalytics(this.state.startDate, this.state.endDate);
  }

  generateAnalytics = () => {
    this.props.store.analytics.generateAnalytics(this.state.startDate, this.state.endDate);
  }

  setDate = (inputDate, planStartDate = this.props.store.auth.user.planStartDate) => {
    console.log('setDate');
    const { startDate, endDate } = this.state;
    const inputMoment = moment(inputDate);
    console.log(`setDate ${inputDate}`);
    let startMoment = moment(startDate);
    let endMoment = moment(endDate);

    if (inputMoment.isBefore(endMoment, 'day')) {
      this.setState({
        startDate: startMoment.toDate(),
        endDate,
      });
    } else {
      this.setState({
        startDate,
        endDate: endMoment.toDate(),
      });
    }

    console.log(`setDate ${startDate} ${endDate}`);
  }

  download = () => {
    const { analyticsData } = this.props.store.analytics;
    const currentRecords = analyticsData;
    var data_to_download = [];
    data_to_download.push(["day","source","medium","domain","forms_started","abandons","abandons/forms_started","unique_valid_abandoned_emails","submits","emails_delivered","emails_opened","unique_email_opens","emails_clicked","unique_email_clicks","recaptures"]);
    for (var index = 0; index < currentRecords.length; index++) {
      data_to_download.push([
        currentRecords[index].day,
        currentRecords[index].source,
        currentRecords[index].medium,
        currentRecords[index].domain,
        currentRecords[index].forms_started,
        currentRecords[index].abandons,
        currentRecords[index].abandons_forms_started,
        currentRecords[index].unique_valid_abandoned_emails,
        currentRecords[index].submits,
        currentRecords[index].emails_delivered,
        currentRecords[index].emails_opened,
        currentRecords[index].unique_email_opens,
        currentRecords[index].emails_clicked,
        currentRecords[index].unique_email_clicks,
        currentRecords[index].recaptures,
      ]);
    }
    this.setState({ dataToDownload: data_to_download }, () => {
      // click the CSVLink component to trigger the CSV download
      this.csvLink.link.click();
    });
  }

  buildRows = () => {
    const { analyticsData } = this.props.store.analytics;
    let i = 0;
    const rows = Object.values(analyticsData)
    .map(row => (
      <Table.Row key={i++}>
        <Table.Cell>{row.day}</Table.Cell>
        <Table.Cell>{row.source}</Table.Cell>
        <Table.Cell>{row.medium}</Table.Cell>
        <Table.Cell>{row.domain}</Table.Cell>
        <Table.Cell>{row.forms_started}</Table.Cell>
        <Table.Cell>{row.abandons}</Table.Cell>
        <Table.Cell>{row.abandons_forms_started}</Table.Cell>
        <Table.Cell>{row.unique_valid_abandoned_emails}</Table.Cell>
        <Table.Cell>{row.submits}</Table.Cell>
        <Table.Cell>{row.emails_delivered}</Table.Cell>
        <Table.Cell>{row.emails_opened}</Table.Cell>
        <Table.Cell>{row.unique_email_opens}</Table.Cell>
        <Table.Cell>{row.emails_clicked}</Table.Cell>
        <Table.Cell>{row.unique_email_clicks}</Table.Cell>
        <Table.Cell>{row.recaptures}</Table.Cell>
      </Table.Row>
    ))
    return rows;
  }

  render() {

    return (
      <Fragment>
        <Header id="TabTitle" as="h2" style={{ display: 'inline-block' }}>
          Analytics
        </Header>
        <Segment>
          {/* <DatePicker
            selectsRange={true}
            showPopperArrow={false}
            onChange={date => this.setDate(date, planStartDate)}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            maxDate={Date.now()}
            isClearable={false}
            popperPlacement="top-end"
          /> */}
          <Button size="mini" primary onClick={this.generateAnalytics} style={{ marginRight: '1.5rem' }}>
            Generate Analytics
          </Button>
          <Button size="mini" primary onClick={this.download} style={{ marginRight: '1.5rem' }}>
            Export to CSV
          </Button>
          <div>
            <CSVLink
              data={this.state.dataToDownload}
              filename="data.csv"
              className="hidden"
              ref={(r) => (this.csvLink = r)}
              target="_blank"
            />
          </div>
        </Segment>
        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <Table celled sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                day
              </Table.HeaderCell>
              <Table.HeaderCell>
                utm_source
              </Table.HeaderCell>
              <Table.HeaderCell>
                utm_medium
              </Table.HeaderCell>
              <Table.HeaderCell>
                domain
              </Table.HeaderCell>
              <Table.HeaderCell>
                forms started
              </Table.HeaderCell>
              <Table.HeaderCell>
                abandons
              </Table.HeaderCell>
              <Table.HeaderCell>
                abandons forms started
              </Table.HeaderCell>
              <Table.HeaderCell>
                unique valid abandoned emails
              </Table.HeaderCell>
              <Table.HeaderCell>
                submits
              </Table.HeaderCell>
              <Table.HeaderCell>
                emails delivered
              </Table.HeaderCell>
              <Table.HeaderCell>
                emails opened
              </Table.HeaderCell>
              <Table.HeaderCell>
                unique email opens
              </Table.HeaderCell>
              <Table.HeaderCell>
                emails clicked
              </Table.HeaderCell>
              <Table.HeaderCell>
                unique email clicks
              </Table.HeaderCell>
              <Table.HeaderCell>
                recaptures
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.buildRows()}
          </Table.Body>
        </Table>
        </div>
      </Fragment>
    )
  }

}

export default Analytics;