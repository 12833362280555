/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { observable, action, computed } from 'mobx';
import { API_URL } from '../configs';

const defaultFilterObject = {
  domain: null,
  path: null,
  timeSpanInMonths: 1,
};

class Analytics {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.emailList = [];
  }

  @observable loading = {
    setTimeSpan: false,
    getRawAnalytics: false,
    getInfluencedSaves: false,
    initiateEmailDownload: false,
    getCaptureList: false,
    getGenerateAnalytics: false,
    analyticsData: false,
  };

  @observable
  analyticsData = {};

  @observable percentView = false;

  @observable filters = defaultFilterObject;

  // object of arrays categorized by timeSpan config, and each api call is cached.
  @observable rawAnalytics = {};

  // influenced saves
  @observable influencedAnalyticsData = {};

  @action reset = () => {
    this.rawAnalytics = {};
    this.filters = defaultFilterObject;
    this.influencedAnalyticsData = {};
    this.emailList = [];
    this.loading = _.mapValues(this.loading, () => false);
    this.analyticsData = {};
  }

  @action setTimeSpan = (e, { value }) => {
    this.loading.setTimeSpan = true;
    this.filters.timeSpanInMonths = value;
    this.getRawAnalytics();
    this.getInfluencedSaves();
    this.loading.setTimeSpan = false;
  }

  @action getRawAnalytics = async () => {
    // By default, the analytics api returns an array of objects containing
    // the statistics per domain + path + date.
    this.loading.getRawAnalytics = true;

    if (!this.rawAnalytics[this.filters.timeSpanInMonths]) {
      const { data } = await axios.post(`${API_URL}/api/analytics/stats`, {
        dateStart: moment().subtract(this.filters.timeSpanInMonths, 'month').format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
      });

      const { rows = [] } = data;
      this.rawAnalytics[this.filters.timeSpanInMonths] = rows.reduce((sortedData, row) => {
        const { domain, path, date_start: dateStart } = row;
        _.set(sortedData, [domain, path, dateStart], row);
        return sortedData;
      }, {});
    }
    this.loading.getRawAnalytics = false;
  }

  @computed
  get filteredAnalytics() {
    const { domain: domainFilter, path: pathFilter } = this.filters;
    let filteredAnalytics = this.rawAnalytics[this.filters.timeSpanInMonths] || {};
    if (domainFilter) {
      filteredAnalytics = _.pick(filteredAnalytics, domainFilter);
    }
    if (pathFilter) {
      filteredAnalytics[domainFilter] = _.pick(
        filteredAnalytics[domainFilter],
        pathFilter,
      );
    }
    return filteredAnalytics;
  }

  @computed
  get chartData() {
    // summary data generator based on raw analytics.
    // Returns an array of statistics object grouped by date_start property
    const chartKeys = ['forms_started', 'abandons', 'unique_valid_abandoned_emails', 'recaptures'];
    // const UNUSED_BUT_AVAILABLE_CHART_KEYS = [
    //   'abandons', 'emails_clicked', 'emails_delivered', 'emails_opened', 'forms_started',
    //   'page_view', 'recaptures', 'remarket_emails', 'sessions', 'submits', 'time_spent',
    //   'unique_email_opens', 'unique_emails_clicked', 'unique_valid_abandoned_emails',
    //   'unique_valid_submitted_emails',
    // ];

    const chartSums = _.reduce(this.filteredAnalytics, (sums, domainNode) => {
      _.forEach(domainNode, (pathNode) => {
        _.forEach(pathNode, (dateData, dateStart) => {
          if (sums[dateStart]) {
            chartKeys.forEach((key) => {
              sums[dateStart][key] += Number(dateData[key]);
            });
          } else {
            sums[dateStart] = _.clone(dateData);
          }
        });
      });
      return sums;
    }, {});

    return Object.entries(chartSums).sort(([aDate], [bDate]) => moment(aDate).diff(moment(bDate)));
  }

  statSum = statKey => _.reduce(this.filteredAnalytics, (sum, domainNode) => {
    _.forEach(domainNode, (pathNode) => {
      _.forEach(pathNode, (rowData) => {
        sum += rowData[statKey];
      });
    });
    return sum;
  }, 0);

  createSummaryRows = (statKeys) => {
    const summaryRows = [];
    _.forEach(this.filteredAnalytics, (domainNode, domain) => {
      _.forEach(domainNode, (pathNode, path) => {
        const url = `${domain}${path}`;
        const statSums = statKeys
          .map(statKey => _.reduce(pathNode, (sum, dateData) => sum + (dateData[statKey] || 0), 0));
        summaryRows.push([
          url,
          ...statSums,
        ]);
      });
    });
    return summaryRows;
  }

  @action getInfluencedSaves = async () => {
    try {
      if (!this.influencedAnalyticsData[this.filters.timeSpanInMonths]) {
        this.loading.getInfluencedSaves = true;

        const res = await axios.post(`${API_URL}/api/reporting/retrieve-influenced-saves`, {
          dateStart: moment().subtract(this.filters.timeSpanInMonths, 'month').format('YYYY-MM-DD'),
          dateEnd: moment().format('YYYY-MM-DD'),

        });
        if (res.data.success) {
          this.influencedAnalyticsData[this.filters.timeSpanInMonths] = res.data.influencedSaves;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading.getInfluencedSaves = false;
    }
  }

  @computed get totalInfluencedSaved() {
    const influencedAnalyticsData = this.influencedAnalyticsData[this.filters.timeSpanInMonths]
      || [];
    if (this.filters.path === null) {
      return influencedAnalyticsData.reduce((agg, item) => agg + item.influenced_saves, 0);
    } else {
      return influencedAnalyticsData
        .filter((item) => {
          if (this.filters.domain !== null) {
            if (this.filters.path !== null) {
              return (
                this.filters.domain === item.host && this.filters.path === item.path
              );
            }

            return (this.filters.domain === item.host);
          }

          return true;
        })
        .reduce((agg, item) => agg + item.influenced_saves, 0) || 0;
    }
  }

  @computed get totalDirectRecaptures() {
    return _.reduce(this.chartData, (sum, [, data]) => sum + data.recaptures, 0);
  }

  @computed get totalSaves() {
    return this.totalDirectRecaptures + this.totalInfluencedSaved;
  }

  // email addresses list
  @observable emailRef;
  @observable emailLoading = false;

  @action getCaptureList = async (startDate = moment().subtract('1', 'month').format('YYYY/MM/DD'), endDate = moment().format('YYYY/MM/DD')) => {
    this.loading.getCaptureList = true;
    try {
      const { data } = await axios.post(`${API_URL}/api/reporting/retrieve-leads`, {
        startDate,
        endDate,
      });

      if (data.success) {
        const { captures } = data;
        const headers = ['Email', 'Capture Url', 'Captured Date', 'Abandoned'];
        const tempList = [];

        for (const capture of captures) {
          const row = new Array(headers.length).fill('');
          row[0] = capture.email;
          row[1] = capture.url;
          row[2] = capture.created_ts;
          row[3] = capture.abandoned;

          const { form_data } = capture;

          for (const column in form_data) {
            if (headers.indexOf(column) === -1) {
              headers.push(column);
              row.push(form_data[column]);
            } else {
              row[headers.indexOf(column)] = form_data[column];
            }
          }

          tempList.push(row.map(val => `"${_.escape(val)}"`));
        }
        for (const row in tempList) {
          while (tempList[row].length < headers.length) {
            tempList[row].push('""');
          }
        }

        tempList.unshift(headers.map(header => `"${header}"`));
        this.emailList = tempList;
      }
    } catch (err) {
      this.rootStore.failure('Failed to download CSV!');
    }
    this.loading.getCaptureList = false;
  }

  @action downloadCaptures = async (startDate, endDate) => {
    this.loading.initiateEmailDownload = true;
    let csvParsedEmailList = '';

    await this.getCaptureList(startDate, moment(endDate).add(1, 'day').toDate());
    if (_.isEmpty(this.emailList)) {
      this.rootStore.failure('Failed to download Captures CSV!');
    }

    for (const rowIndex in this.emailList) {
      csvParsedEmailList += `${this.emailList[rowIndex].join(',')}\n`;
    }

    if (this.emailRef) {
      this.emailRef.href = URL.createObjectURL(new Blob([csvParsedEmailList], { type: 'text/csv' }));
      this.emailRef.download = `${moment(startDate).format('MM/DD/YYYY')}-${moment(endDate).format('MM/DD/YYYY')}_abandoned_emails.csv`;
      this.emailRef.click();
    }

    this.loading.initiateEmailDownload = false;
  }

  @action
  generateAnalytics = async (startDate, endDate) => {
    try {
      this.loading.getGenerateAnalytics = true;
      const res = await axios.post(`${API_URL}/api/reporting/analytics-domain`, {
        dateStart: moment().subtract(7, "days").format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),

      });
      if (res.data.success) {
        this.analyticsData = res.data.data;
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading.getInfluencedSaves = false;
    }
  }
}

export default Analytics;
